import(/* webpackMode: "eager", webpackExports: ["Root","displayName","List","Item","Trigger","Content","Link","Viewport","Indicator"] */ "/vercel/path0/node_modules/@radix-ui/react-navigation-menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Figtree\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-figtree\",\"display\":\"swap\"}],\"variableName\":\"fontFigtree\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-jetbrains-mono\",\"display\":\"swap\"}],\"variableName\":\"fontJetBrainsMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/logo.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/svg/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/svg/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/svg/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/svg/mbd.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/svg/youtube.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[language]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/cookie/Cookie.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/error/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/header/client/LoginSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header/components/headerNavigationMenu.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/header/NavigationSheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/providers/ClientProviders.tsx");
